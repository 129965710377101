import React, { useState, useEffect } from 'react';
import { Box, Typography, IconButton, Fab, Slide, Fade } from '@mui/material';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import CloseIcon from '@mui/icons-material/Close';
import SendIcon from '@mui/icons-material/Send';
import EvoFuture from '../assets/EvoFuture.ico';
// import notification from '../assets/Audio/notification.mp3';

const FloatingWhatsAppButton = () => {
  const [showPopup, setShowPopup] = useState(false);
  const [showButton, setShowButton] = useState(true);

  useEffect(() => {
    // Check if the popup was already shown in this session
    const popupShown = sessionStorage.getItem('whatsAppPopupShown');
    if (!popupShown) {
      const timer = setTimeout(() => {
        setShowPopup(true);
        sessionStorage.setItem('whatsAppPopupShown', 'true'); // Mark as shown
      }, 27000); // Delay before showing popup (15 seconds)
      return () => clearTimeout(timer); // Cleanup timer on component unmount
    }
  }, []);

  const handleOpenChat = () => {
    window.open('https://wa.me/94769374123', '_blank');
  };

  const handleClosePopup = () => {
    setShowPopup(false);
  };

  return (
    <Box
      sx={{
        position: 'fixed',
        bottom: 20,
        right: 20,
        zIndex: 9999,
      }}
    >
      {/* WhatsApp Popup */}
      <Slide direction="up" in={showPopup} mountOnEnter unmountOnExit>
        <Box
          sx={{
            position: 'relative',
            backgroundColor: '#ffffff',
            borderRadius: '16px',
            boxShadow: '0 8px 20px rgba(0, 0, 0, 0.2)',
            overflow: 'hidden',
            width: '340px',
            maxWidth: '95%', // Adjusts for mobile responsiveness
          }}
        >
          {/* Header */}
          <Box
            sx={{
              backgroundColor: '#128C7E',
              padding: '12px 16px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              color: '#ffffff',
            }}
          >
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <img
                src={EvoFuture}
                alt="Company Logo"
                style={{
                  width: '24px',
                  height: '24px',
                  marginRight: '8px',
                  borderRadius: '50%',
                }}
              />
              <Typography
                variant="subtitle1"
                sx={{ fontWeight: 'bold', fontSize: '16px' }}
              >
                EvoFuture
              </Typography>
            </Box>
            <IconButton
              size="small"
              sx={{ color: '#ffffff' }}
              onClick={handleClosePopup}
            >
              <CloseIcon />
            </IconButton>
          </Box>

          {/* Chat Content */}
          <Box
            sx={{
              padding: '16px',
              backgroundColor: '#f5f5f5',
              height: '180px',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                backgroundColor: '#ffffff',
                padding: '12px',
                borderRadius: '10px',
                boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
                marginBottom: '8px',
              }}
            >
              <WhatsAppIcon
                sx={{
                  fontSize: '40px',
                  color: '#25D366',
                  marginRight: '12px',
                }}
              />
              <Box>
                <Typography
                  variant="body2"
                  sx={{ color: '#333', fontWeight: 'bold' }}
                >
                  Hey there! 👋
                </Typography>
                <Typography variant="body2" sx={{ color: '#555' }}>
                  How can We help you today?
                </Typography>
              </Box>
            </Box>
            <Fab
              variant="extended"
              color="primary"
              onClick={handleOpenChat}
              sx={{
                backgroundColor: '#25D366',
                color: '#ffffff',
                width: '100%',
                justifyContent: 'center',
                '&:hover': {
                  backgroundColor: '#128C7E',
                },
              }}
            >
              <SendIcon sx={{ marginRight: '8px' }} />
              Start Chat
            </Fab>
          </Box>
        </Box>
      </Slide>

      {/* Floating Button */}
      {showButton && (
        <Fade in={!showPopup}>
          <Fab
            color="primary"
            onClick={() => setShowPopup(true)}
            sx={{
              backgroundColor: '#25D366',
              color: '#ffffff',
              '&:hover': {
                backgroundColor: '#128C7E',
              },
              position: 'absolute',
              bottom: 0,
              right: 0,
              boxShadow: '0 4px 12px rgba(0, 0, 0, 0.3)',
            }}
          >
            <WhatsAppIcon />
          </Fab>
        </Fade>
      )}
    </Box>
  );
};

export default FloatingWhatsAppButton;
