import * as React from 'react';
import {
  Box,
  Typography,
  TextField,
  Button,
  MenuItem,
  Paper,
  Alert,
} from '@mui/material';
import axios from 'axios';

const categories = [
  'Web Development', 'WordPress', 'UI/UX', 'Graphics', 'IT Assignments', 'Network Solutions', 'CCTV'
];

// Use environment variable for the API base URL
const apiBaseUrl = process.env.REACT_APP_API_BASE_URL || 'http://localhost:8080';

function AddProjectForm() {
  const [name, setName] = React.useState('');
  const [description, setDescription] = React.useState('');
  const [category, setCategory] = React.useState('');
  const [images, setImages] = React.useState([]); // Change to handle multiple images
  const [successMessage, setSuccessMessage] = React.useState('');
  const [errorMessage, setErrorMessage] = React.useState('');

  const handleImageChange = (e) => {
    const selectedFiles = Array.from(e.target.files);
    if (selectedFiles.length > 10) {
      alert('You can upload a maximum of 10 images.');
    } else {
      setImages(selectedFiles); // Save all selected images
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append('name', name);
    formData.append('description', description);
    formData.append('category', category);
    images.forEach((img) => {
      formData.append('images', img); // Append each image
    });

    try {
      const token = localStorage.getItem('authToken'); // Retrieve the token from localStorage
      if (!token) {
        setErrorMessage('Your authentication token is invalid or not found. Please log in again to continue.');
        return;
      }
  
      const response = await axios.post(`${apiBaseUrl}/api/projects`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${token}`, // Include the token in the header
        },
      });
      console.log('Project added:', response.data);
      setSuccessMessage('Successfully submitted!');
      setName('');
      setDescription('');
      setCategory('');
      setImages([]);
    } catch (error) {
      console.error('Error adding project:', error);
      if (error.response && error.response.status === 403) {
        setErrorMessage('Access Denied: You are not authorized to perform this action.');
      } else {
        setErrorMessage('Error submitting project. Please try again.');
      }
    }
  };

  return (
    <Box 
      display="flex" 
      justifyContent="center" 
      alignItems="center" 
      minHeight="100vh"
      sx={{ backgroundColor: 'inherit', padding: 2 }}
    >
      <Paper 
        elevation={2}
        sx={{
          padding: 3,
          width: '100%',
          maxWidth: '500px',
          borderRadius: 5,
        }}
      >
        <Typography variant="h5" component="h1" gutterBottom align="center">
          Add New Project
        </Typography>
        
        {successMessage && <Alert severity="success" onClose={() => setSuccessMessage('')}>{successMessage}</Alert>}
        {errorMessage && <Alert severity="error" onClose={() => setErrorMessage('')}>{errorMessage}</Alert>}

        <Box component="form" onSubmit={handleSubmit} sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
          <TextField 
            label="Name" 
            value={name} 
            onChange={(e) => setName(e.target.value)} 
            required 
            fullWidth 
          />
          <TextField 
            label="Description" 
            value={description} 
            onChange={(e) => setDescription(e.target.value)} 
            required 
            multiline 
            rows={3} 
            fullWidth 
          />
          <TextField 
            select 
            label="Category" 
            value={category} 
            onChange={(e) => setCategory(e.target.value)} 
            required 
            fullWidth
          >
            {categories.map((option) => (
              <MenuItem key={option} value={option}>{option}</MenuItem>
            ))}
          </TextField>
          <Button 
            variant="outlined" 
            component="label" 
            sx={{ padding: 1 }}
          >
            Upload Image
            <input type="file" hidden multiple onChange={handleImageChange} accept="image/*" />
          </Button>
          {images.length > 0 && (
            <Typography variant="body2" color="textSecondary">
              {images.length} image{images.length > 1 ? 's' : ''} selected
            </Typography>
          )}
          <Button 
            type="submit" 
            variant="contained" 
            color="primary" 
            fullWidth
          >
            Submit
          </Button>
        </Box>
      </Paper>
    </Box>
  );
}

export default AddProjectForm;
