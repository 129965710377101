import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

const PrivateRoute = ({ children }) => {
  const navigate = useNavigate();

  // Use environment variable for the API base URL
  const apiBaseUrl = process.env.REACT_APP_API_BASE_URL || 'http://localhost:8080';

  useEffect(() => {
    const validateToken = async () => {
      const token = localStorage.getItem('authToken');
      if (!token) {
        navigate('/login'); // Redirect to login if no token is present
        return;
      }
      try {
        const response = await axios.get(`${apiBaseUrl}/api/auth/validate`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        if (response.status !== 200) {
          navigate('/login'); // Redirect to login if the token is invalid
        }
      } catch (err) {
        navigate('/login'); // Redirect to login if validation fails
      }
    };

    validateToken();
  }, [navigate]);

  return children; // Render the protected children if validation passes
};

export default PrivateRoute;
