import * as React from 'react';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import IconButton from '@mui/material/IconButton';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
// import BankTransferIcon from '@mui/icons-material/AccountBalance';
// import OnlinePaymentIcon from '@mui/icons-material/Payment';
// import WorldIcon from '@mui/icons-material/Public';

function Copyright() {
  return (
    <Typography variant="body2" sx={{ color: 'text.secondary', mt: 1 }}>
      © {new Date().getFullYear()}{' '}
      <Link href="#" color="text.secondary" underline="none">
        EvoFuture
      </Link>
      . All rights reserved.
    </Typography>
  );
}

export default function Footer() {
  return (
    <Container
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: { xs: 4, sm: 8 },
        py: { xs: 8, sm: 10 },
        textAlign: { sm: 'center', md: 'left' },
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: { xs: 'column', sm: 'row' },
          width: '100%',
          justifyContent: 'space-between',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 4,
            minWidth: { xs: '100%', sm: '60%' },
          }}
        ></Box>
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          pt: { xs: 4, sm: 8 },
          width: '100%',
          borderTop: '1px solid',
          borderColor: 'divider',
        }}
      >
        <Link color="text.secondary" variant="body2" href="/Terms&Conditions">
          Privacy Policy & Terms of Service
        </Link>
        <Copyright />
        <Stack
          direction="row"
          spacing={1}
          useFlexGap
          sx={{ justifyContent: 'center', color: 'text.secondary', mt: 2 }}
        >
          {/* 
          <IconButton
            color="inherit"
            size="small"
            href="#"
            aria-label="Bank Transfer"
            sx={{ alignSelf: 'center' }}
          >
            <BankTransferIcon />
          </IconButton>
          <IconButton
            color="inherit"
            size="small"
            href="#"
            aria-label="Online Payment"
            sx={{ alignSelf: 'center' }}
          >
            <OnlinePaymentIcon />
          </IconButton>
          */}
        </Stack>
      </Box>
    </Container>
  );
}
