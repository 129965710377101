import * as React from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import WebIcon from '@mui/icons-material/Web';
import PhonelinkIcon from '@mui/icons-material/Phonelink';
import BrushIcon from '@mui/icons-material/Brush';
import AssignmentIcon from '@mui/icons-material/Assignment';
import LaptopIcon from '@mui/icons-material/Laptop';
import StorageIcon from '@mui/icons-material/Storage';
import CameraOutdoorRoundedIcon from '@mui/icons-material/CameraOutdoorRounded';
import { useTheme } from '@mui/material/styles';

const items = [
  {
    icon: <WebIcon sx={{ fontSize: 40, color: '#027AF2' }} />,
    title: <strong>Website Development</strong>,
    description:
      'We specialize in creating custom, responsive websites tailored to your brand and audience. From sleek portfolios to dynamic business platforms, we ensure your online presence stands out.',
  },
  {
    icon: <PhonelinkIcon sx={{ fontSize: 40, color: '#027AF2' }} />,
    title: <strong>UI/UX Design</strong>,
    description:
      'We provide end-to-end digital solutions, including web design, UI/UX, cybersecurity, cloud computing, and IT infrastructure, tailored to enhance your business or personal projects.',
  },
  {
    icon: <BrushIcon sx={{ fontSize: 40, color: '#027AF2' }} />,
    title: <strong>Graphic Design</strong>,
    description:
      'Bring your brand to life with stunning graphic design, including logos, branding, and marketing materials, tailored to captivate and inspire.',
  },
  {
    icon: <AssignmentIcon sx={{ fontSize: 40, color: '#027AF2' }} />,
    title: <strong>IT Assignment Write</strong>,
    description:
      'Get expert IT assignment writing services with in-depth research and accurate solutions, tailored to your specific academic needs.',
  },
  {
    icon: <StorageIcon sx={{ fontSize: 40, color: '#027AF2' }} />,
    title: <strong>Network Solutions</strong>,
    description:
      'We offer comprehensive network solutions, including design, implementation, and management, ensuring optimal performance, security, and scalability for your business infrastructure.',
  },
  {
    icon: <LaptopIcon sx={{ fontSize: 40, color: '#027AF2' }} />,
    title: <strong>WordPress Development</strong>,
    description:
      'Our WordPress development services deliver fully customized websites that are user-friendly, responsive, and scalable to grow with your business.',
  },
  {
    icon: <CameraOutdoorRoundedIcon sx={{ fontSize: 40, color: '#027AF2' }} />,
    title: <strong>CCTV Service</strong>,
    description:
      'Our CCTV services offer advanced surveillance solutions, including installation and maintenance, to provide reliable, high-definition monitoring for enhanced security and peace of mind.',
  },
];

export default function Services() {
  const [isInView, setIsInView] = React.useState(false);
  const ref = React.useRef(null);
  const theme = useTheme();

  React.useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => setIsInView(entry.isIntersecting),
      { threshold: 0.2 }
    );

    if (ref.current) observer.observe(ref.current);

    return () => observer.disconnect();
  }, []);

  return (
    <Box
      id="highlights"
      sx={{
        pt: { xs: 4, sm: 12 },
        pb: { xs: 8, sm: 16 },
        color: theme.palette.mode === 'dark' ? 'white' : 'black',
        bgcolor: theme.palette.mode === 'dark' ? 'grey.900' : 'grey.100',
      }}
      ref={ref}
    >
      <Container
        sx={{
          position: 'relative',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          gap: { xs: 3, sm: 6 },
        }}
      >
        <Box
          sx={{
            width: { sm: '100%', md: '60%' },
            textAlign: 'center',
          }}
        >
          <Typography component="h2" variant="h4" gutterBottom>
            Services We Provide
          </Typography>
          <Typography
            variant="body1"
            sx={{
              color: theme.palette.mode === 'dark' ? 'grey.400' : 'grey.700',
              fontSize: { xs: '0.875rem', sm: '1rem' },
            }}
          >
            Explore our services: innovative website development, seamless UI/UX design, impactful graphic design, expert IT assignment support, reliable network solutions, scalable WordPress development, and advanced CCTV, all designed to elevate your business.
          </Typography>
        </Box>
        <Grid container spacing={2}>
          {items.map((item, index) => (
            <Grid item xs={12} sm={6} md={4} key={index}>
              <Stack
                direction="column"
                component={Card}
                spacing={2}
                alignItems="center"
                justifyContent="center"
                sx={{
                  color: 'inherit',
                  p: 3,
                  height: 300,
                  backgroundColor: theme.palette.mode === 'dark' ? 'grey.800' : 'white',
                  opacity: isInView ? 1 : 0,
                  transform: isInView ? 'translateY(0)' : 'translateY(50px)',
                  transition: 'all 0.7s ease-out',
                  borderRadius: '16px',
                  boxShadow: theme.palette.mode === 'dark'
                    ? '0px 2px 4px rgba(255, 255, 255, 0.1)'
                    : '0px 2px 4px rgba(0, 0, 0, 0.1)',
                }}
              >
                <Box>{item.icon}</Box>
                <Typography
                  gutterBottom
                  sx={{ fontWeight: 'medium', fontSize: '1rem', textAlign: 'center' }}
                >
                  {item.title}
                </Typography>
                <Typography
                  variant="body2"
                  sx={{ color: theme.palette.mode === 'dark' ? 'grey.400' : 'grey.700', textAlign: 'center' }}
                >
                  {item.description}
                </Typography>
              </Stack>
            </Grid>
          ))}
        </Grid>
      </Container>
    </Box>
  );
}
