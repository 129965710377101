import React, { useEffect, useState } from 'react';
import {
  Box,
  Card,
  Container,
  Grid,
  Stack,
  Typography,
  CircularProgress,
  Dialog,
  DialogContent,
  Button,
  IconButton,
  TextField,
  InputAdornment,
} from '@mui/material';
import ZoomOutMapIcon from '@mui/icons-material/ZoomOutMap';
import CloseIcon from '@mui/icons-material/Close';
import SearchIcon from '@mui/icons-material/Search';
import axios from 'axios';
import AppAppBar from './AppAppBar';
import Footer from './Footer';

const categories = [
  'Web Development',
  'WordPress',
  'UI/UX',
  'Graphics',
  'IT Assignments',
  'Network Solutions',
  'CCTV',
];

const ProjectPage = () => {
  const [projects, setProjects] = useState([]);
  const [filteredProjects, setFilteredProjects] = useState([]);
  const [categoryFilter, setCategoryFilter] = useState('');
  const [searchQuery, setSearchQuery] = useState('');
  const [loading, setLoading] = useState(true);
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);

  // Use environment variable for the API base URL
  const apiBaseUrl = process.env.REACT_APP_API_BASE_URL || 'http://localhost:8080';

  useEffect(() => {
    const fetchProjects = async () => {
      try {
        const response = await axios.get(`${apiBaseUrl}/api/projects`);
        setProjects(response.data);
        setFilteredProjects(response.data);
      } catch (error) {
        console.error('Error fetching projects:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchProjects();
  }, []);

  const handleFilterChange = (category) => {
    setCategoryFilter(category);
    const filteredByCategory = category ? projects.filter((p) => p.category === category) : projects;
    const filtered = filteredByCategory.filter(
      (p) =>
        p.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
        p.category.toLowerCase().includes(searchQuery.toLowerCase())
    );
    setFilteredProjects(filtered);
  };

  const handleSearchChange = (event) => {
    const query = event.target.value.toLowerCase();
    setSearchQuery(query);
    const filtered = projects.filter(
      (p) =>
        (categoryFilter === '' || p.category === categoryFilter) &&
        (p.name.toLowerCase().includes(query) || p.category.toLowerCase().includes(query))
    );
    setFilteredProjects(filtered);
  };

  const handleImageClick = (image) => {
    setSelectedImage(image);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setSelectedImage(null);
  };

  return (
    <>
      <AppAppBar />
      <br />
      <br />
      <br />
      <br />
      <br />
      <Box sx={{ padding: 4, textAlign: 'center', bgcolor: 'background.default', minHeight: '100vh', color: 'text.primary' }}>
        {loading ? (
          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
            <CircularProgress color="primary" size={60} />
          </Box>
        ) : (
          <Container>
            {/* Search Bar */}
            <Box sx={{ mb: 4, display: 'flex', justifyContent: 'center' }}>
              <TextField
                variant="outlined"
                placeholder="Search projects by name or category"
                fullWidth
                sx={{ maxWidth: '600px' }}
                value={searchQuery}
                onChange={handleSearchChange}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
              />
            </Box>

            {/* Category Filter Buttons */}
            <Box sx={{ mb: 4, display: 'flex', justifyContent: 'center', gap: 2, flexWrap: 'wrap' }}>
              <Button
                onClick={() => handleFilterChange('')}
                variant={categoryFilter === '' ? 'contained' : 'outlined'}
                sx={{ color: 'text.secondary', borderColor: 'primary.main' }}
              >
                All
              </Button>
              {categories.map((category) => (
                <Button
                  key={category}
                  onClick={() => handleFilterChange(category)}
                  variant={categoryFilter === category ? 'contained' : 'outlined'}
                  sx={{ color: 'text.secondary', borderColor: 'primary.main' }}
                >
                  {category}
                </Button>
              ))}
            </Box>

            {/* Project Grid */}
            <Grid container spacing={3}>
              {filteredProjects.map((project) => (
                <Grid item xs={12} sm={6} md={4} key={project.id}>
                  <Stack
                    component={Card}
                    spacing={2}
                    sx={{
                      height: '100%',
                      p: 3,
                      bgcolor: 'background.paper',
                      borderRadius: 3,
                      boxShadow: 3,
                      color: 'text.primary',
                      position: 'relative',
                      overflow: 'hidden',
                      transition: 'transform 0.3s, box-shadow 0.3s',
                      '&:hover': {
                        transform: 'translateY(-5px)',
                        boxShadow: '0 8px 20px rgba(0, 0, 0, 0.2)',
                      },
                    }}
                  >
                    <Box
                      component="img"
                      src={`data:image/jpeg;base64,${project.image}`}
                      alt={project.name}
                      className="project-image"
                      sx={{
                        width: '100%',
                        height: 200,
                        borderRadius: 2,
                        transition: 'transform 0.3s ease-in-out',
                        objectFit: 'cover',
                        '&:hover': {
                          transform: 'scale(1.1)',
                        },
                      }}
                    />
                    <Typography variant="h6" sx={{ fontWeight: 'bold', textAlign: 'center' }}>
                      {project.name}
                    </Typography>
                    <Typography variant="body2" sx={{ color: 'text.secondary', textAlign: 'center' }}>
                      {project.description}
                    </Typography>
                    <Typography variant="caption" sx={{ color: 'text.secondary', textAlign: 'center', fontStyle: 'italic' }}>
                      Category: {project.category}
                    </Typography>

                    {/* Zoom Icon Button */}
                    <Button
                      onClick={() => handleImageClick(project.image)}
                      style={{
                        position: 'absolute',
                        bottom: 16,
                        right: 16,
                        backgroundColor: '#F1F3F8',
                        color: 'black',
                        minWidth: 0,
                        padding: '10px',
                        borderRadius: '50%',
                        boxShadow: '0 4px 10px rgba(0, 0, 0, 0.6)',
                        transition: 'transform 0.3s ease, box-shadow 0.3s ease',
                      }}
                      sx={{
                        '&:hover': {
                          transform: 'scale(1.1)',
                          boxShadow: '0 6px 15px rgba(0, 0, 0, 0.8)',
                          backgroundColor: '#E0E3E8',
                        },
                      }}
                    >
                      <ZoomOutMapIcon sx={{ fontSize: 20 }} />
                    </Button>
                  </Stack>
                </Grid>
              ))}
            </Grid>

            {/* Image Zoom Dialog */}
            <Dialog
              open={openDialog}
              onClose={handleCloseDialog}
              maxWidth="md"
              PaperProps={{ sx: { backgroundColor: 'rgba(255, 255, 255, 0.9)', boxShadow: 'none' } }}
              BackdropProps={{ style: { backgroundColor: 'rgba(0, 0, 0, 0.7)' } }}
            >
              <DialogContent sx={{ p: 0, position: 'relative' }}>
                <IconButton
                  onClick={handleCloseDialog}
                  sx={{
                    position: 'absolute',
                    top: 8,
                    right: 8,
                    backgroundColor: 'rgba(255, 255, 255, 0.7)',
                    // '&:hover': { backgroundColor: 'rgba(255, 255, 255, 1)' },
                  }}
                >
                  <CloseIcon />
                </IconButton>
                <img
                  src={`data:image/jpeg;base64,${selectedImage}`}
                  alt="Zoomed Project"
                  style={{ width: '100%', height: 'auto', borderRadius: '10px' }}
                />
              </DialogContent>
            </Dialog>
          </Container>
        )}
      </Box>
      <Footer />
    </>
  );
};

export default ProjectPage;
