import React, { useState, useEffect } from 'react';
import {
  Box, Typography, TextField, MenuItem, Paper, Table, TableBody,
  TableCell, TableHead, TableRow, TablePagination, Button, LinearProgress
} from '@mui/material';
import axios from 'axios';

function ShowProjects() {
  const [projects, setProjects] = useState([]);
  const [categoryFilter, setCategoryFilter] = useState('');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [loading, setLoading] = useState(true); 

  // Use environment variable for the API base URL
  const apiBaseUrl = process.env.REACT_APP_API_BASE_URL || 'http://localhost:8080';

  const fetchProjects = async () => {
    setLoading(true); // Start loading
    try {
      const response = await axios.get(`${apiBaseUrl}/api/projects`);
      setProjects(response.data);
    } catch (error) {
      console.error('Error fetching projects:', error);
    } finally {
      setLoading(false); // Stop loading
    }
  };  

  useEffect(() => {
    fetchProjects();
  }, []);

  const handleChangePage = (event, newPage) => setPage(newPage);

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const filteredProjects = categoryFilter
    ? projects.filter((project) => project.category === categoryFilter)
    : projects;

  return (
    <Box sx={{ padding: 2, display: 'flex', flexDirection: 'column', alignItems: 'center' }}><br></br>
      <Typography variant="h5" component="h2" gutterBottom>All Projects</Typography><br></br>
      <TextField
        select label="Filter by Category" value={categoryFilter}
        onChange={(e) => setCategoryFilter(e.target.value)} fullWidth sx={{ marginBottom: 2 }}
      >
        <MenuItem value="">All Categories</MenuItem>
        {['Web Development', 'WordPress', 'UI/UX', 'Graphics', 'IT Assignments', 'Network Solutions', 'CCTV'].map((option) => (
          <MenuItem key={option} value={option}>{option}</MenuItem>
        ))}
      </TextField>
      {loading && <LinearProgress sx={{ width: '100%', marginBottom: 2 }} />}

      <Paper sx={{ width: '100%', overflow: 'hidden', display: 'flex', justifyContent: 'center' }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Image</TableCell>
              <TableCell>Name</TableCell>
              <TableCell>Description</TableCell>
              <TableCell>Category</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredProjects.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((project) => (
              <TableRow key={project.id}>
                <TableCell>
                  {project.image && (
                    <img
                      src={`data:image/jpeg;base64,${project.image}`}
                      alt={project.name} style={{ width: 50, height: 50, objectFit: 'cover', borderRadius: '10px', }}
                    />
                  )}
                </TableCell>
                <TableCell>{project.name}</TableCell>
                <TableCell>{project.description}</TableCell>
                <TableCell>{project.category}</TableCell>
                <TableCell>
                  <Button>Update</Button>
                  <Button color="error">Delete</Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Paper>
      <TablePagination
        rowsPerPageOptions={[20, 50, 100]} component="div"
        count={filteredProjects.length} rowsPerPage={rowsPerPage}
        page={page} onPageChange={handleChangePage} onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Box>
  );
}

export default ShowProjects;
