import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  Card,
  Container,
  Typography,
  styled,
} from '@mui/material';
import {
  Brush as BrushIcon,
  Phonelink as PhonelinkIcon,
  Web as WebIcon,
  Assignment as AssignmentIcon,
  Laptop as LaptopIcon,
  Storage as StorageIcon,
  CameraOutdoorRounded as CameraOutdoorRoundedIcon,
} from '@mui/icons-material';

// Static image imports
import web from '../assets/web.png';
import ui from '../assets/ui.png';
import network from '../assets/network.png';
import graphic from '../assets/graphic.png';
import assi from '../assets/assi.png';
import wp from '../assets/wp.png';
import cctv from '../assets/ct.png'

// Project items data
const projectItems = [
  {
    icon: <WebIcon />,
    title: 'Web Development',
    description: 'Explore our custom-built websites, designed to create a unique and engaging online presence.',
    image: web,
  },
  {
    icon: <PhonelinkIcon />,
    title: 'UI/UX Design',
    description: 'Discover our UI/UX designs that deliver seamless and user-friendly digital experiences.',
    image: ui,
  },
  {
    icon: <StorageIcon />,
    title: 'Network Solutions',
    description: 'See how our network solutions help businesses stay secure, connected, and scalable.',
    image: network,
  },
  {
    icon: <BrushIcon />,
    title: 'Graphic Design',
    description: 'View our creative graphic design projects that enhance brand identity and communication.',
    image: graphic,
  },
  {
    icon: <AssignmentIcon />,
    title: 'IT Assignment Support',
    description: 'Check out the high-quality IT assignments we’ve delivered with detailed research and solutions.',
    image: assi,
  },
  {
    icon: <LaptopIcon />,
    title: 'WordPress Development',
    description: 'Browse our WordPress development projects, offering customized solutions to meet diverse business needs.',
    image: wp,
  },
  {
    icon: <CameraOutdoorRoundedIcon />,
    title: 'CCTV Service',
    description: 'Explore our CCTV projects providing reliable security and surveillance systems for peace of mind.',
    image: cctv,
  },
];

// Styled components
const FeatureImage = styled(Box)(({ theme, image }) => ({
  height: 300,
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  backgroundImage: `url(${image})`,
  borderRadius: theme.shape.borderRadius,
}));

const Indicator = styled(Box)(({ theme, active }) => ({
  width: 8,
  height: 8,
  borderRadius: '50%',
  backgroundColor: active ? theme.palette.primary.main : theme.palette.grey[400],
  margin: '0 4px',
}));

const Projects = () => {
  const [currentIndex, setCurrentIndex] = useState(0);

  // Auto-rotation effect
  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % projectItems.length);
    }, 3000); // Transition interval
    return () => clearInterval(intervalId); // Cleanup on component unmount
  }, []);

  const handleIndicatorClick = (index) => {
    setCurrentIndex(index);
  };

  const currentFeature = projectItems[currentIndex];

  return (
    <Container id="features" sx={{ py: { xs: 8, sm: 16 }, textAlign: 'center' }}>
      <Typography variant="h4" gutterBottom sx={{ color: 'text.primary' }}>
        What We Have Done
      </Typography>
      <Typography variant="body1" sx={{ color: 'text.secondary', mb: { xs: 2, sm: 4 } }}>
        Explore our portfolio of completed projects, demonstrating our expertise and commitment to excellence.
      </Typography>
      <Card variant="outlined" sx={{ p: 4, mt: 4 }}>
        <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2 }}>
          {currentFeature.icon}
        </Box>
        <Typography variant="h6" gutterBottom>
          {currentFeature.title}
        </Typography>
        <Typography variant="body2" sx={{ color: 'text.secondary' }}>
          {currentFeature.description}
        </Typography>
        {currentFeature.image && (
          <FeatureImage image={currentFeature.image} sx={{ mt: 4 }} />
        )}
      </Card>
      {/* Blue Circle Indicators */}
      <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
        {projectItems.map((_, index) => (
          <Indicator
            key={index}
            active={index === currentIndex}
            onClick={() => handleIndicatorClick(index)}
            sx={{ cursor: 'pointer' }}
          />
        ))}
      </Box>
      <Button variant="contained" color="primary" size="large" sx={{ mt: 4 }} href="/projects">
        View All Projects
      </Button>
    </Container>
  );
};

Projects.propTypes = {
  currentIndex: PropTypes.number,
};

export default Projects;
