import React, { useRef } from 'react';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Container from '@mui/material/Container';
import { useTheme } from '@mui/system';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import senithiImage from '../assets/senithi.png';
import samadhiImage from '../assets/samadhi.png';
import pasinduiImage from '../assets/pasindu.png';


const userTestimonials = [
  {
    avatar: <Avatar alt="Senithi Gehansa" src={senithiImage} />,
    name: 'Senithi Gehansa',
    occupation: 'Undergraduate',
    testimonial:
      "Excellent service at all times. The IT assignment writing service from EvoFuture changed everything for me. The content was excellent, based on extensive study, and delivered on time. They answered all of my questions and gave me exactly what I needed. Highly recommended for anyone who needs solid IT academic help",
  },
  {
    avatar: <Avatar alt="Samadhi Bhagya" src={samadhiImage} />,
    name: 'Samadhi Bhagya',
    occupation: 'Software Engineer',
    testimonial:
      "EvoFuture did a great job on my IT assignment and they were super helpful and delivered everything on time. Highly recommend their service.",
  },
  {
    avatar: <Avatar alt="Pasindu Lakshan" src={pasinduiImage} />,
    name: 'Pasindu Lakshan',
    occupation: 'HR executive',
    testimonial:
      'Great access point setup, fast, smooth, and boosted our network. Highly recommend EvoFuture',
  },
  {
    avatar: <Avatar alt="Shifan Abdulla" /*src="/static/images/avatar/5.jpg"*/ />,
    name: 'Shifan Abdulla',
    occupation: 'Undergraduate',
    testimonial:
      "EvoFuture did an amazing job creating images for our small business. They captured our brand perfectly and provided exactly what we needed. Highly recommend their services",
  },
  {
    avatar: <Avatar alt="Wageesha Nanayakkara" /*src="/static/images/avatar/6.jpg"*/ />,
    name: 'Wageesha Nanayakkara',
    occupation: 'Cloud Network engineer',
    testimonial:
      "EvoFuture created my landing page, and I'm really happy with the results. They listened to my ideas and delivered a great design that attracts more visitors. I highly recommend their services.",
  },
  
  {
    avatar: <Avatar alt="Samith Desitha" /*src="/static/images/avatar/4.jpg"*/ />,
    name: 'Samith Desitha',
    occupation: 'Doctor',
    testimonial:
      "Excellent service, highly recommended",
  }, 
];

export default function Testimonials() {
  const theme = useTheme();
  const scrollContainerRef = useRef(null);

  const handleScroll = (direction) => {
    if (scrollContainerRef.current) {
      const scrollAmount = direction === 'left' ? -300 : 300;
      scrollContainerRef.current.scrollBy({ left: scrollAmount, behavior: 'smooth' });
    }
  };

  return (
    <Container
      id="testimonials"
      sx={{
        pt: { xs: 4, sm: 12 },
        pb: { xs: 8, sm: 16 },
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: { xs: 3, sm: 6 },
      }}
    >
      <Typography
        component="h2"
        variant="h4"
        gutterBottom
        sx={{ color: 'text.primary', textAlign: 'center' }}
      >
        Testimonials
      </Typography>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          position: 'relative',
          width: '100%',
        }}
      >
        <Box
          ref={scrollContainerRef}
          sx={{
            display: 'flex',
            overflowX: 'auto',
            scrollSnapType: 'x mandatory',
            gap: 2,
            px: 4,
            '&::-webkit-scrollbar': { display: 'none' },
          }}
        >
          {userTestimonials.map((testimonial, index) => (
            <Card
              key={index}
              variant="outlined"
              sx={{
                flex: '0 0 300px',
                scrollSnapAlign: 'start',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
              }}
            >
              <CardContent>
                <Typography
                  variant="body1"
                  gutterBottom
                  sx={{ color: 'text.secondary' }}
                >
                  {testimonial.testimonial}
                </Typography>
              </CardContent>
              <CardHeader
                avatar={testimonial.avatar}
                title={testimonial.name}
                subheader={testimonial.occupation}
              />
            </Card>
          ))}
        </Box>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            width: '100%',
            position: 'absolute',
            bottom: -60, // Adjusted to move buttons further down
            zIndex: 1,
          }}
        >
          <IconButton
            onClick={() => handleScroll('left')}
            sx={{
              marginRight: 2, // Space between buttons
            }}
          >
            <ArrowBackIosIcon />
          </IconButton>
          <IconButton
            onClick={() => handleScroll('right')}
            sx={{
              marginLeft: 2, // Space between buttons
            }}
          >
            <ArrowForwardIosIcon />
          </IconButton>
        </Box>
      </Box>
    </Container>
  );
}
