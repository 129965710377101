import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import PhoneIcon from '@mui/icons-material/Phone';
import EmailIcon from '@mui/icons-material/Email';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import { SiLinktree } from "react-icons/si";

export default function ContactUs() {
  return (
    <Container
      id="contact-us"
      sx={{
        pt: { xs: 4, sm: 12 },
        pb: { xs: 8, sm: 16 },
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: { xs: 3, sm: 6 },
      }}
    >
      <Box
        sx={{
          width: { xs: '100%', md: '60%' },
          textAlign: 'center',
        }}
      >
        <Typography
          component="h2"
          variant="h4"
          gutterBottom
          sx={{ color: 'text.primary', textAlign: 'center' }}
        >
          Contact Us
        </Typography>
        <Typography variant="body1" sx={{ color: 'text.secondary', textAlign: 'center' }}>
          Reach out to us through any of the following methods:
        </Typography>
      </Box>

      <Grid
        container
        spacing={3}
        sx={{ alignItems: 'center', justifyContent: 'center', width: '100%' }}
      >
        <Grid item xs={12} sm={6} md={4}>
          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 2 }}>
            <Typography variant="h6" sx={{ color: 'text.primary' }}>
              Call Us
            </Typography>
            <Button
              startIcon={<PhoneIcon />}
              href="tel:+94 76 937 4123"
              variant="outlined"
              sx={{ width: '100%' }}
            >
              +94 76 937 4123
            </Button>
          </Box>
        </Grid>

        <Grid item xs={12} sm={6} md={4}>
          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 2 }}>
            <Typography variant="h6" sx={{ color: 'text.primary' }}>
              Email Us
            </Typography>
            <Button
              startIcon={<EmailIcon />}
              href="mailto:team.evofuture@gmail.com"
              variant="outlined"
              sx={{ width: '100%' }}
            >
              team.evofuture@gmail.com
            </Button>
          </Box>
        </Grid>

        <Grid item xs={12} sm={12} md={12}>
          <Box sx={{ display: 'flex', justifyContent: 'center', gap: 3 }}>
            <IconButton
              color="primary"
              href="https://www.facebook.com/evofuture"
              target="_blank"
            >
              <FacebookIcon />
            </IconButton>
            <IconButton
              color="primary"
              href="https://instagram.com/official_evofuture"
              target="_blank"
            >
              <InstagramIcon />
            </IconButton>
            <IconButton
              color="primary"
              href="https://www.linkedin.com/company/evo-future/"
              target="_blank"
            >
              <LinkedInIcon />
            </IconButton>
            <IconButton
              color="primary"
              href="https://wa.me/94769374123"
              target="_blank"
            >
              <WhatsAppIcon />
            </IconButton>
            <IconButton
              color="primary"
              href="https://linktr.ee/EvoFuture"
              target="_blank"
            >
              <SiLinktree />
            </IconButton>
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
}
