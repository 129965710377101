import React, { useState, useEffect } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import WebIcon from '@mui/icons-material/Web';
import AssignmentIcon from '@mui/icons-material/Assignment';
import DesignServicesIcon from '@mui/icons-material/DesignServices';
import BrushIcon from '@mui/icons-material/Brush';
import WifiIcon from '@mui/icons-material/Wifi';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';

const StyledDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialog-paper': {
    backgroundColor: '#F4F4F4',
    color: '#000',
    borderRadius: '16px',
    padding: '15px',
    boxShadow: '0 10px 30px rgba(0, 0, 0, 0.1)',
    width: '90%',
    maxWidth: '500px',
    height: 'auto',
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    overflow: 'hidden',
    [theme.breakpoints.down('sm')]: {
      maxWidth: '90%',
    },
  },
}));

const StyledDialogTitle = styled(DialogTitle)(({ theme }) => ({
  fontSize: '1.2rem',
  fontWeight: 'bold',
  textAlign: 'left',
  padding: '8px 16px',
  marginBottom: '12px',
  display: 'flex',
  alignItems: 'center',
  gap: '8px',
}));

const CloseButton = styled(IconButton)(({ theme }) => ({
  position: 'absolute',
  top: '8px',
  right: '8px',
  backgroundColor: '#000', // Black background
  color: '#fff', // White icon color
  borderRadius: '50%', // Circular button
  padding: '8px', // Padding to make the button large enough
  '&:hover': {
    backgroundColor: '#333', // Darker on hover
  },
}));

const StyledDialogContent = styled(DialogContent)(({ theme }) => ({
  padding: '15px',
  fontSize: '0.95rem',
  textAlign: 'left',
  display: 'flex',
  flexDirection: 'column',
  gap: '10px',
  '& ul': {
    margin: 0,
    padding: 0,
    listStyle: 'none',
    width: '100%',
  },
  '& li': {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    fontSize: '0.95rem',
    marginBottom: '12px',
    padding: '8px',
    background: '#fff',
    borderRadius: '10px',
    gap: '12px',
    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.08)',
    [theme.breakpoints.down('sm')]: {
      fontSize: '0.85rem',
    },
  },
}));

const IconWrapper = styled(Box)(({ theme }) => ({
  width: '3vw', // Responsive size using viewport width
  height: '3vw',
  borderRadius: '50%',
  backgroundColor: '#000',
  color: '#fff',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  fontSize: '2vw',
  [theme.breakpoints.down('sm')]: {
    width: '8vw',
    height: '8vw',
    fontSize: '4vw',
  },
}));

export default function OffersDialog() {
  const [openDialog, setOpenDialog] = useState(false);

  useEffect(() => {
    // Check if the dialog was already shown in this session
    const dialogShown = sessionStorage.getItem('offersDialogShown');
    if (!dialogShown) {
      const timer = setTimeout(() => {
        setOpenDialog(true);
        sessionStorage.setItem('offersDialogShown', 'true'); // Mark as shown
      }, 20000); // Open after 10 seconds
      return () => clearTimeout(timer);
    }
  }, []);

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  return (
    <StyledDialog open={openDialog} onClose={handleCloseDialog} aria-labelledby="offers-dialog-title">
      {/* Close Button */}
      <CloseButton aria-label="close" onClick={handleCloseDialog}>
        <CloseIcon />
      </CloseButton>

      {/* Dialog Title */}
      <StyledDialogTitle id="offers-dialog-title">
        <CheckCircleOutlineIcon style={{ fontSize: '1.3rem' }} />
        Exclusive Winter deals
      </StyledDialogTitle>

      {/* Dialog Content */}
      <StyledDialogContent>
        <p style={{ fontWeight: 'bold', fontSize: '1rem' }}>
          Unlock amazing deals tailored to meet your business needs
        </p>
        <ul>
          <li>
            <IconWrapper>
              <WebIcon />
            </IconWrapper>
            50% Off first website project!
          </li>
          <li>
            <IconWrapper>
              <AssignmentIcon />
            </IconWrapper>
            50% Off first IT assignment!
          </li>
          <li>
            <IconWrapper>
              <DesignServicesIcon />
            </IconWrapper>
            50% Off first UI design!
          </li>
          <li>
            <IconWrapper>
              <BrushIcon />
            </IconWrapper>
            6 Free Designs to start!
          </li>
          <li>
            <IconWrapper>
              <WifiIcon />
            </IconWrapper>
            Discounts on network & CCTV!
          </li>
        </ul>
      </StyledDialogContent>
    </StyledDialog>
  );
}
