// src/BlogPage.js

import React, { useState, useEffect } from 'react';
import {
  Box,
  Grid,
  Card,
  CardMedia,
  CardContent,
  CardActionArea,
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  Divider,
  CircularProgress,
  IconButton,
  TextField,
  InputAdornment,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';
import blogs from './blogs'; // Importing the blogs data
import AppAppBar from './AppAppBar';
import Footer from './Footer';

export default function BlogPage() {
  const [selectedBlog, setSelectedBlog] = useState(null);
  const [loading, setLoading] = useState(true); // State to manage loading
  const [searchQuery, setSearchQuery] = useState(''); // State for search input
  const [filteredBlogs, setFilteredBlogs] = useState(blogs); // Filtered blogs

  const handleBlogClick = (blog) => {
    setSelectedBlog(blog);
  };

  const handleClose = () => {
    setSelectedBlog(null);
  };

  const handleSearchChange = (event) => {
    const query = event.target.value.toLowerCase();
    setSearchQuery(query);

    const filtered = blogs.filter(
      (blog) =>
        blog.title.toLowerCase().includes(query) || blog.category.toLowerCase().includes(query)
    );

    setFilteredBlogs(filtered);
  };

  // Simulate loading of blog data (replace with actual API call)
  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 2000); // Simulating a 2-second delay
  }, []);

  return (
    <Box sx={{ minHeight: '100vh', bgcolor: 'background.default' }}>
      <AppAppBar />
      <br></br><br></br><br></br><br></br><br></br><br></br>

      {/* Spacing and Divider after AppBar
      <Box sx={{ mt: 12, mb: 2 }}>
        <Divider />
      </Box> */}

      <Box sx={{ mx: 'auto', maxWidth: '1200px', px: 3 }}>
        <Typography
          variant="h4"
          gutterBottom
          align="center"
          sx={{ fontWeight: 'bold', color: 'primary.main', mb: 4 }}
        >
          Explore Our Blog
        </Typography>

        {/* Search Bar */}
        <Box sx={{ mb: 4, display: 'flex', justifyContent: 'center' }}>
          <TextField
            variant="outlined"
            placeholder="Search by title or category"
            fullWidth
            sx={{ maxWidth: '600px' }}
            value={searchQuery}
            onChange={handleSearchChange}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
        </Box>

        {loading ? (
          <Box sx={{ display: 'flex', justifyContent: 'center', mt: 5 }}>
            <CircularProgress />
          </Box>
        ) : filteredBlogs.length === 0 ? (
          <Typography variant="h6" color="text.secondary" align="center">
            No blogs found matching your search.
          </Typography>
        ) : (
          <Grid container spacing={4}>
            {filteredBlogs.map((blog) => (
              <Grid item xs={12} sm={6} md={4} key={blog.id}>
                <Card
                  sx={{
                    boxShadow: 3,
                    transition: 'transform 0.3s, box-shadow 0.3s',
                    '&:hover': {
                      transform: 'translateY(-10px)',
                      boxShadow: 6,
                    },
                  }}
                >
                  <CardActionArea onClick={() => handleBlogClick(blog)}>
                    <CardMedia
                      component="img"
                      height="160"
                      image={blog.thumbnail}
                      alt={blog.title}
                      sx={{ borderRadius: '8px 8px 8px 8px' }}
                    />
                    <CardContent>
                      <Typography gutterBottom variant="h6" component="div" sx={{ fontWeight: 'bold' }}>
                        {blog.title}
                      </Typography>
                      <Typography
                        variant="body2"
                        color="text.secondary"
                        sx={{
                          display: '-webkit-box',
                          WebkitBoxOrient: 'vertical',
                          WebkitLineClamp: 3,
                          overflow: 'hidden',
                        }}
                      >
                        {blog.description}
                      </Typography>
                      <Box sx={{ mt: 2 }}>
                        <Typography variant="caption" color="text.secondary">
                          Category: {blog.category}
                        </Typography>
                        <Typography variant="caption" color="text.secondary" sx={{ display: 'block' }}>
                          Created on: {blog.createdDate} at {blog.createdTime}
                        </Typography>
                        <Typography variant="caption" color="text.secondary">
                          Author: {blog.author}
                        </Typography>
                      </Box>
                    </CardContent>
                  </CardActionArea>
                </Card>
              </Grid>
            ))}
          </Grid>
        )}
      </Box>

      {/* Spacing and Divider before Footer */}
      {/* <Box sx={{ mt: 15, mb: 2 }}> */}
        {/* <Divider /> */}
      {/* </Box> */}

      <Footer />

      <Dialog open={Boolean(selectedBlog)} onClose={handleClose} maxWidth="md" fullWidth>
        {selectedBlog && (
          <>
            <DialogTitle sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              {selectedBlog.title}
              <IconButton onClick={handleClose}>
                <CloseIcon />
              </IconButton>
            </DialogTitle>
            <DialogContent>
              <CardMedia
                component="img"
                height="300"
                image={selectedBlog.fullImage}
                alt={`${selectedBlog.title} - Full Image`}
                sx={{ borderRadius: '8px', mb: 3 }}
              />
              <Typography variant="body1" sx={{ whiteSpace: 'pre-line' }} dangerouslySetInnerHTML={{ __html: selectedBlog.content }} />
            </DialogContent>
          </>
        )}
      </Dialog>
    </Box>
  );
}
