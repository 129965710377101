import * as React from 'react';
import PropTypes from 'prop-types';
import { Typography, IconButton, Dialog, DialogActions, DialogContent, DialogTitle, Button } from '@mui/material';
import { createTheme } from '@mui/material/styles';
import DashboardIcon from '@mui/icons-material/Dashboard';
import AddIcon from '@mui/icons-material/Add';
import RateReviewIcon from '@mui/icons-material/RateReview';
import LogoutIcon from '@mui/icons-material/Logout'; // Import the Logout icon
import { AppProvider } from '@toolpad/core/AppProvider';
import { DashboardLayout } from '@toolpad/core/DashboardLayout';
import { useDemoRouter } from '@toolpad/core/internal';
import ShowProjects from './ShowProjects';
import AddProjectForm from './AddProjectForm';
import { useNavigate } from 'react-router-dom';

const NAVIGATION = [
  { segment: 'dashboard', title: 'Dashboard', icon: <DashboardIcon /> },
  { segment: 'projects', title: 'Add Projects', icon: <AddIcon /> },
  { segment: 'show-projects', title: 'Show Projects', icon: <RateReviewIcon /> },
  // { segment: 'reviews', title: 'Reviews', icon: <RateReviewIcon /> },
];

const demoTheme = createTheme({
  cssVariables: {
    colorSchemeSelector: 'data-toolpad-color-scheme',
  },
  colorSchemes: { light: true, dark: true },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 600,
      lg: 1200,
      xl: 1536,
    },
  },
});

function DemoPageContent({ pathname }) {
  switch (pathname) {
    case '/dashboard':
      return <Typography>Welcome to the Dashboard!</Typography>;
    case '/projects':
      return <AddProjectForm />;
    case '/show-projects':
      return <ShowProjects />;
    // case '/reviews':
    //   return <Typography>Reviews content goes here.</Typography>;
    default:
      return <Typography>Page not found</Typography>;
  }
}

DemoPageContent.propTypes = {
  pathname: PropTypes.string.isRequired,
};

function DashboardLayoutBranding(props) {
  const { window } = props;
  const router = useDemoRouter('/dashboard');
  const navigate = useNavigate();
  const [openDialog, setOpenDialog] = React.useState(false); // State to control dialog visibility

  const demoWindow = window !== undefined ? window() : undefined;

  // Handle opening the logout confirmation dialog
  const handleLogoutClick = () => {
    setOpenDialog(true); // Open the confirmation dialog
  };

  // Handle closing the dialog without logging out
  const handleDialogClose = () => {
    setOpenDialog(false); // Close the dialog
  };

  // Handle logging out and redirecting to login page
  const handleConfirmLogout = () => {
    // Remove the JWT token from localStorage
    localStorage.removeItem('authToken');
    // Redirect to login page
    navigate('/login');
    setOpenDialog(false); // Close the dialog after logout
  };

  return (
    <AppProvider
      navigation={NAVIGATION}
      branding={{
        title: 'Evo Future Backend Dashboard',
      }}
      router={router}
      theme={demoTheme}
      window={demoWindow}
    >
      <DashboardLayout>
        <DemoPageContent pathname={router.pathname} />
        
        {/* Logout Icon Button */}
        <IconButton
          color="secondary"
          onClick={handleLogoutClick}
          sx={{
            position: 'fixed',
            bottom: 20,
            left: 20,
            zIndex: 9999,
            padding: '10px',
          }}
        >
          <LogoutIcon /> {/* Logout Icon */}
        </IconButton>

        {/* Logout Confirmation Dialog */}
        <Dialog open={openDialog} onClose={handleDialogClose}>
          <DialogTitle>Confirm Logout</DialogTitle>
          <DialogContent>
            <Typography>Are you sure you want to log out?</Typography>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleDialogClose} color="primary">
              Cancel
            </Button>
            <Button onClick={handleConfirmLogout} color="secondary">
              Logout
            </Button>
          </DialogActions>
        </Dialog>
      </DashboardLayout>
    </AppProvider>
  );
}

DashboardLayoutBranding.propTypes = {
  window: PropTypes.func,
};

export default DashboardLayoutBranding;
