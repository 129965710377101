import React, { useState, useEffect } from 'react';
import { Fab, keyframes, useTheme } from '@mui/material';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';

// Keyframes for arrow animation
const bounceAnimation = keyframes`
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-5px);
  }
`;

const ScrollToTopButton = () => {
  const [isVisible, setIsVisible] = useState(false);
  const theme = useTheme(); // Access the current theme

  // Show or hide the button depending on scroll position
  useEffect(() => {
    const toggleVisibility = () => {
      if (window.scrollY > 300) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    };

    window.addEventListener('scroll', toggleVisibility);

    return () => window.removeEventListener('scroll', toggleVisibility);
  }, []);

  // Scroll to the top of the page
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth', // Smooth scroll animation
    });
  };

  return (
    <>
      {isVisible && (
        <Fab
          onClick={scrollToTop}
          size="small" // Makes the button smaller
          sx={{
            position: 'fixed',
            bottom: 20, // Adjusts the vertical position
            left: '50%', // Centers horizontally
            transform: 'translateX(-50%)', // Centers the button precisely
            zIndex: 999,
            backgroundColor: theme.palette.background.paper, // Background matches current theme
            border: `1px solid ${theme.palette.divider}`, // Border color matches theme divider color
            color: theme.palette.text.primary, // Icon color matches text color
            transition: 'all 0.3s ease', // Smooth transition for hover effect
            '&:hover': {
              backgroundColor: theme.palette.action.hover, // Hover background color based on theme
              borderColor: theme.palette.divider, // Border color on hover
            },
            '&:focus': {
              backgroundColor: theme.palette.action.hover, // Focus background color based on theme
              borderColor: theme.palette.divider, // Border color on focus
            },
          }}
        >
          <ArrowUpwardIcon
            sx={{
              animation: `${bounceAnimation} 1.5s infinite`, // Adds bounce animation
              fontSize: '1.2rem', // Slightly smaller arrow
            }}
          />
        </Fab>
      )}
    </>
  );
};

export default ScrollToTopButton;
