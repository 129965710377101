// src/BusinessAgreementPage.js

import React from 'react';
import { Box, Typography, Divider, Container, Grid, Card, CardContent, Button, List, ListItem, ListItemText } from '@mui/material';
import AppAppBar from './AppAppBar';
import Footer from './Footer';

export default function BusinessAgreementPage() {
  return (
    <Box sx={{ minHeight: '100vh', bgcolor: 'background.paper' }}>
      <AppAppBar />

      {/* Spacing and Divider after AppBar */}
      {/* <Box sx={{ mt: 10, mb: 4 }}> */}
        {/* <Divider /> */}
      {/* </Box> */}
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <br></br>

      <Container sx={{ maxWidth: '1200px', px: 3 }}>
        <Typography variant="h4" sx={{ fontWeight: 'bold', mb: 4 }}>
          EvoFuture - Business Services Agreement
        </Typography>

        <Card sx={{ mb: 4, boxShadow: 3 }}>
          <CardContent>
            <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
              Company Name: EvoFuture
            </Typography>
            <Typography variant="body1" sx={{ mt: 1 }}>
              <strong>Business Address: </strong>Colombo & Galle
              <br />
              <strong>Phone:</strong> +94 76 937 4123
              <br />
              <strong>Email:</strong> team.evofuture@gmail.com
              <br />
              {/* <strong>Date:</strong> [Insert Date] */}
            </Typography>
          </CardContent>
        </Card>

        <Typography variant="body1" paragraph>
          {/* <strong>Dear [Client's Name],</strong>
          <br /> */}
          Thank you for your interest in partnering with EvoFuture. We are a leading IT solutions company, and although our business registration in Sri Lanka is currently in progress, we are excited to offer you our top-notch services. We take pride in delivering quality results, and we are confident that our work will exceed your expectations. Below, we have outlined our services and terms to provide clarity and build trust in our collaboration.
        </Typography>

        <Typography variant="h5" sx={{ fontWeight: 'bold', mt: 4 }}>
          Our Services:
        </Typography>
        <Card sx={{ mt: 2 }}>
          <CardContent>
            <List>
              <ListItem>
                <ListItemText primary="Web Development" secondary="Custom websites, e-commerce platforms, and portfolio sites designed to elevate your online presence." />
              </ListItem>
              <ListItem>
                <ListItemText primary="UI/UX Design" secondary="Professional design solutions that enhance user experience and boost engagement." />
              </ListItem>
              <ListItem>
                <ListItemText primary="IT Assignment Writing" secondary="Expert IT reports, research, and assignments tailored to meet academic and business needs." />
              </ListItem>
              <ListItem>
                <ListItemText primary="WordPress Development" secondary="Customized WordPress solutions, including theme development and site maintenance." />
              </ListItem>
              <ListItem>
                <ListItemText primary="Graphic Design" secondary="Stunning visual designs for social media, advertising, and branding, and more." />
              </ListItem>
              <ListItem>
                <ListItemText primary="Network Solutions & Implementation" secondary="Tailored network setup and solutions to meet your specific needs including Network Implementation & Maintenance, Server Management, Network Troubleshooting all designed to ensure security, efficiency, and smooth operation." />
              </ListItem>
              <ListItem>
                <ListItemText primary="CCTV Services" secondary="We offer complete CCTV solutions, including installation, maintenance, upgrades, remote monitoring, and custom surveillance options for ultimate security." />
              </ListItem>
            </List>
          </CardContent>
        </Card>

        <Typography variant="h5" sx={{ fontWeight: 'bold', mt: 4 }}>
          Special Offers (Limited-Time Offers):
        </Typography>
        <Card sx={{ mt: 2 }}>
          <CardContent>
            <List>
              <ListItem>
                <ListItemText primary="50% Off your first website development project with us." />
              </ListItem>
              <ListItem>
                <ListItemText primary="50% Off your first IT assignment with us." />
              </ListItem>
              <ListItem>
                <ListItemText primary="50% Off your first WordPress project with us." />
              </ListItem>
              <ListItem>
                <ListItemText primary="50% Off your first UI design with us." />
              </ListItem>
              <ListItem>
                <ListItemText primary="First 6 graphic designs for free with the option to continue only if satisfied." />
              </ListItem>
              <ListItem>
                <ListItemText primary="Special Discount based on your network and CCTV project with us." />
              </ListItem>
            </List>
          </CardContent>
        </Card>

        <Typography variant="h5" sx={{ fontWeight: 'bold', mt: 4 }}>
          Terms of Service:
        </Typography>
        <Card sx={{ mt: 2 }}>
          <CardContent>
            <List>
              <ListItem>
                <ListItemText primary="Scope of Work" secondary="Our team will provide the services listed above, based on your specific project requirements." />
              </ListItem>
              <ListItem>
                <ListItemText primary="Timeline" secondary="Projects will be completed in accordance with agreed timelines, and we will keep you updated on all developments." />
              </ListItem>
              <ListItem>
                <ListItemText primary="Payment Terms" secondary="25% advance payment is required before we commence work on your project." />
              </ListItem>
              <ListItem>
                <ListItemText primary="Revisions" secondary="We offer revisions for all projects to ensure your satisfaction." />
              </ListItem>
              <ListItem>
                <ListItemText primary="Confidentiality" secondary="All your business data and information will be treated with the utmost confidentiality." />
              </ListItem>
              <ListItem>
                <ListItemText primary="Client Engagement" secondary="We require you to maintain regular communication with us until the project is completed and work begins once you have approved the project proposal." />
              </ListItem>
            </List>
          </CardContent>
        </Card>

        <Typography variant="h5" sx={{ fontWeight: 'bold', mt: 4 }}>
          Temporary Business Registration:
        </Typography>
        <br />
        <Typography variant="body1" paragraph>
          While our formal registration is being processed, please rest assured that EvoFuture operates with complete transparency and professionalism. We have taken steps to establish an online presence, client testimonials, and partnerships to ensure trust in our services.
        </Typography>

        <Typography variant="h5" sx={{ fontWeight: 'bold', mt: 4 }}>
          Social Media & Online Presence:
        </Typography>
        <Card sx={{ mt: 2 }}>
          <CardContent>
            <List>
              <ListItem>
                <ListItemText primary="Facebook" secondary="EvoFuture" />
              </ListItem>
              <ListItem>
                <ListItemText primary="Instagram" secondary="official_evofuture" />
              </ListItem>
              <ListItem>
                <ListItemText primary="LinkedIn" secondary="Evo Future" />
              </ListItem>
              <ListItem>
                <ListItemText primary="TikTok" secondary="official_evofuture" />
              </ListItem>
              <ListItem>
                <ListItemText primary="LinkTree" secondary="Evo Future" />
              </ListItem>
            </List>
          </CardContent>
        </Card>

        <Typography variant="h5" sx={{ fontWeight: 'bold', mt: 4 }}>
          Contact Us:
        </Typography>
        <br />
        <Typography variant="body1" paragraph>
          Feel free to reach out to us at any time for further clarification, project discussions, or service inquiries. We are here to help and look forward to collaborating with you.
          <br />
          <br />
          <strong>Phone:</strong> +94 76 937 4123
          <br />
          <strong>Email:</strong> team.evofuture@gmail.com
        </Typography>

        <Typography variant="body1" paragraph>
          Thank you for considering EvoFuture. We look forward to working with you to bring your vision to life.
        </Typography>

        <Typography variant="body1" paragraph>
          Sincerely, <br />
          {/* [Your Name] <br /> */}
          EvoFuture Team
        </Typography>

        {/* <Box sx={{ mt: 4 }}>
          <Divider />
        </Box> */}

        <Footer />
      </Container>
    </Box>
  );
}
