import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Slider from 'react-slick';
import {
  SiVisualstudiocode, SiWebstorm, SiIntellijidea, SiPycharm, SiWordpress,
  SiHtml5, SiCss3, SiJavascript, SiPhp, SiMysql, SiPostgresql, SiReact,
  SiSpringboot, SiNodedotjs, SiCanva, SiFigma, SiAdobephotoshop, SiAdobelightroom,
  SiGithub, SiHostinger, SiPostman, SiApachetomcat, SiApache, SiAdobeillustrator,
  SiGrammarly, SiCisco, SiMicrosoftazure, SiGooglecloud, SiNetgear, SiLinux, SiTailwindcss,
} from 'react-icons/si';
import { FaAws, FaNpm, FaJava, FaNode } from 'react-icons/fa';
import { SiTplink } from "react-icons/si";
import { FaGolang } from "react-icons/fa6";
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const icons = [
  <SiVisualstudiocode />, <SiWebstorm />, <SiIntellijidea />, <SiPycharm />,
  <SiWordpress />, <SiHtml5 />, <SiCss3 />, <SiJavascript />, <SiPhp />, <FaJava />,
  <FaGolang />, <SiTailwindcss />, <SiMysql />, <SiPostgresql />, <SiReact />, 
  <SiSpringboot />, <SiNodedotjs />, <FaNode />, <SiCanva />, <SiFigma />, <SiAdobephotoshop />,
  <SiAdobelightroom />, <SiGithub />, <FaAws />, <SiHostinger />,
  <FaNpm />, <SiPostman />, <SiApachetomcat />, <SiApache />,
  <SiAdobeillustrator />, <SiGrammarly />, <SiCisco />, <SiMicrosoftazure />, 
  <SiGooglecloud />, <SiLinux />, <SiNetgear />, <SiTplink />
];

const settings = {
  dots: false,
  infinite: true,
  speed: 5000,
  slidesToShow: 5,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 0,
  cssEase: 'linear',
  swipe: true, // Enables touch/swipe support
};

export default function LogoCollection() {
  return (
    <Box id="logoCollection" sx={{ py: 4 }}>
      <Typography
        component="h2"
        variant="h4"
        align="center"
        sx={{ mb: 2 }}
      >
        Our Tech Stack
      </Typography>
      <br />
      <Slider {...settings}>
        {icons.map((icon, index) => (
          <Box
            key={index}
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              fontSize: '3rem',
              color: 'text.secondary',
              mx: { xs: 0.5, sm: 1, md: 2 },
            }}
          >
            {icon}
          </Box>
        ))}
      </Slider>
    </Box>
  );
}
